import React from 'react';

function Title({titleName, subTitle}) {
  return (
    <div>
      <h1 className='main-title'>{titleName}</h1>
      <div className='sub-title home-page-cardImg-title'>{subTitle}</div>
      <div className='title-under-line'></div>
    </div>
  );
}

export default Title;
