/* eslint-disable jsx-a11y/anchor-is-valid */
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ServiceCard from '../components/service-card';
// import { Data } from './Data';

function ServiceDetails({ Heading, para, images, data}) {
    return (
        <Container className="about-main-container">
            <Row xs={1} md={1} lg={2}>
                <Col className="ser-detail-img-section">
                    <img src={images} alt="react" />
                </Col>
                <Col>
                    <div className="ser-detail-content-main">
                        <div className="ser-detail-heading">{Heading}</div>
                        <div className="ser-detail-para">{para}</div>
                        {/* <div className="about-page-core-card-super-container"> */}
                            <Container className="about-page-core-card-main-container">
                                <Row xs={1} sm={2} md={2} lg={2} className="about-core-card-container">
                                    {
                                        data.map(
                                            (value) => {
                                                return <>
                                                    <ServiceCard cardIcon={value.coreIcon} cardHeading={value.coreheading} cardPara={value.corepara} />
                                                </>
                                            }
                                        )
                                    }
                                </Row>
                            </Container>
                        {/* </div> */}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ServiceDetails;