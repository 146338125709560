import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
// import Navigationbar from '../components/navbar';
import ServiceDetails from '../components/services-top-details';
import Banner from '../components/page-banner';
import Footer from '../components/footer';
import { Data } from '../components/Data';
import Experts from '../components/experts';
import ContactCard from '../components/contact-card';
import Serwhychoose from '../components/ser-WhyChoose';
import { NavbarWithMegaMenu } from "../NavbarWithMegaMenu";
import Faqs from '../components/faqs';

import ReactBanner from '../images/react-banner2.png';
import { Helmet } from 'react-helmet-async';

export default function WordPress() {
  return (
    <div className="page-super-container services-page">
      <Helmet>
            <title>WordPress Development Services | Elevate User Interaction with Montech Design</title>
            <meta
                name="description"
                content="Enhance your online presence with Montech Design's comprehensive WordPress website design services. Our customized, feature-rich solutions elevate user experience and drive growth."
            />
            <meta
                name="keywords"
                content="WordPress development, website design, user experience, custom WordPress solutions, Montech Design, responsive WordPress design, digital growth"
            />
        </Helmet>
        {/* <Navigationbar /> */}
      <NavbarWithMegaMenu />
    
      <Banner page="WordPress" bannerimg={ReactBanner} />

      <div>
        {
          Data.wordpresspage.map(
            (value) => {
              return <ServiceDetails
                Heading={value.Heading}
                para={value.para}
                images={value.images}
                data={Data.serWordpressCard}
              />
            }
          )
        }
      </div>

      {/* Card */}
      <div className="contact-super-container">
        <Container className="contact-main-container">
          <Row>
            <Col>
              <div className="about-main-heading">ADVANTAGES OF UTILIZING WORDPRESS WEBSITES</div>
              <div className="about-sub-heading">Experience Growth With The Industry's Top WordPress Design and Development Experts</div>
            </Col>
          </Row>
          <Row xs={1} sm={2} md={2} lg={3} className="contact-card-container">
            {
              Data.serWordpressPaheCard.map(
                (value) => {
                  return <>
                    <ContactCard contactIcon={value.aboutcardtIcon} contactHeading={value.aboutcardheading} contactPara={value.aboutcardpara} />
                  </>
                }
              )
            }
          </Row>
        </Container>
      </div>

      <Experts
        serHeading="WORDPRESS DEVELOPMENT SERVICES"
        sersubHeading="Specializing In The Provision Of Superior Personalized WordPress Solutions"
        data={Data.wordpressexpert}
      />
      
      <div>
        {
          Data.serWhyChooseWordpress.map(
            (value) => {
              return <Serwhychoose
                Heading={value.Heading}
                subHeading={value.subHeading}
                para1={value.para1}
                para2={value.para2}
                para3={value.para3}
                images={value.images}
              />
            }
          )
        }
      </div>

      <Faqs
        faqHeading="FREQUENTLY ASKED QUESTIONS FOR WORDPRESS DEVELOPMENT SERVICES"
        faqsubHeading="Discover Answers to Common Queries Regarding WordPress Development Services"
        data={Data.wordpressFaqs}
      />

      <Footer />

    </div>
  )
}
