import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#0089CF"
      stroke="none"
    >
      <path d="M64 5110 c-12 -4 -31 -21 -43 -36 -20 -26 -21 -35 -21 -348 0 -300 1 -323 20 -353 13 -21 31 -35 57 -42 41 -12 578 -15 632 -5 17 4 42 18 56 32 24 24 25 30 25 143 l0 119 413 0 412 0 -105 -56 c-691 -367 -1136 -1043 -1206 -1831 l-6 -71 -120 -4 -120 -3 -29 -33 -29 -32 0 -323 c0 -301 1 -324 20 -354 13 -21 31 -35 57 -42 41 -12 578 -15 632 -5 17 4 42 18 56 32 l25 25 0 334 c0 210 -4 341 -10 354 -20 37 -63 49 -175 49 l-106 0 6 73 c71 803 617 1507 1377 1775 77 27 244 72 269 72 5 0 9 -35 9 -79 0 -93 12 -133 48 -158 25 -16 54 -18 352 -18 298 0 327 2 352 18 36 25 48 65 48 158 0 44 4 79 9 79 25 0 192 -45 269 -72 760 -268 1306 -972 1377 -1775 l6 -73 -106 0 c-112 0 -155 -12 -175 -49 -6 -13 -10 -144 -10 -354 l0 -334 25 -25 c14 -14 39 -28 56 -32 54 -10 591 -7 632 5 25 7 45 21 58 42 18 30 19 49 17 360 l-3 329 -33 29 c-32 28 -35 29 -146 29 l-114 0 -6 73 c-50 556 -285 1061 -672 1439 -172 169 -328 282 -534 392 l-105 56 413 0 412 0 0 -119 c0 -113 1 -119 25 -143 14 -14 39 -28 56 -32 54 -10 591 -7 632 5 25 7 45 21 58 42 18 30 19 49 17 360 l-3 329 -33 29 -32 29 -323 0 c-417 0 -395 11 -400 -190 l-2 -105 -681 -3 -682 -2 -3 120 c-4 115 -5 121 -32 147 l-27 28 -340 0 -340 0 -27 -28 c-27 -26 -28 -32 -32 -147 l-3 -120 -682 2 -681 3 -2 105 c-4 134 -7 147 -46 170 -30 19 -51 20 -347 19 -173 0 -325 -4 -336 -9z m526 -390 l0 -200 -195 0 -195 0 0 200 0 200 195 0 195 0 0 -200z m2168 3 l-3 -198 -195 0 -195 0 -3 198 -2 197 200 0 200 0 -2 -197z m2162 -3 l0 -200 -195 0 -195 0 0 200 0 200 195 0 195 0 0 -200z m-4330 -2460 l0 -200 -195 0 -195 0 0 200 0 200 195 0 195 0 0 -200z m4330 0 l0 -200 -195 0 -195 0 0 200 0 200 195 0 195 0 0 -200z" />
      <path d="M2509 4053 c-18 -11 -94 -139 -242 -409 -239 -433 -242 -440 -188 -495 36 -35 86 -39 124 -9 14 11 71 104 128 207 56 104 108 197 116 208 11 16 13 -38 13 -345 l0 -365 -50 -24 c-60 -29 -133 -104 -162 -168 -33 -72 -31 -199 3 -273 33 -70 99 -136 169 -169 79 -37 201 -37 280 0 70 33 136 99 169 169 34 74 36 201 3 273 -29 64 -102 139 -162 168 l-50 24 0 364 c0 200 2 362 5 359 11 -10 675 -1222 675 -1231 0 -5 -22 -34 -50 -63 -164 -181 -302 -440 -361 -681 l-22 -93 -347 0 -347 0 -22 93 c-59 241 -197 500 -361 681 -28 29 -50 58 -50 63 0 5 27 58 60 119 33 60 60 119 60 131 0 74 -75 123 -142 92 -27 -12 -48 -44 -143 -217 -85 -154 -85 -166 8 -261 183 -187 299 -387 366 -630 l19 -69 -81 -4 c-107 -6 -173 -35 -231 -99 -24 -27 -51 -70 -60 -96 -20 -60 -20 -146 0 -206 17 -50 92 -142 124 -152 19 -6 20 -18 20 -441 l0 -434 28 -30 c17 -19 40 -33 63 -36 31 -5 42 -1 70 24 l34 30 3 421 3 421 579 0 580 0 0 -415 0 -415 28 -30 c17 -19 40 -33 63 -36 31 -5 42 -1 70 24 l34 30 3 441 c3 424 3 440 22 446 32 10 107 102 124 152 20 60 20 146 0 206 -9 26 -36 69 -60 96 -58 64 -124 93 -231 99 l-81 4 19 69 c67 243 183 443 366 630 107 109 146 10 -392 987 -354 642 -475 854 -496 866 -34 20 -67 20 -100 -1z m130 -1420 c33 -24 61 -79 61 -118 0 -40 -32 -90 -72 -115 -93 -56 -216 21 -205 129 11 107 132 165 216 104z m611 -1348 c35 -18 50 -43 50 -85 0 -42 -15 -67 -50 -85 -44 -23 -1336 -23 -1380 0 -35 18 -50 43 -50 85 0 41 14 66 48 84 41 23 1339 23 1382 1z" />
      <path d="M1930 2989 c-36 -14 -60 -49 -60 -87 0 -44 14 -68 50 -87 58 -30 110 -10 136 51 12 29 12 39 0 68 -14 33 -59 67 -88 65 -7 0 -24 -5 -38 -10z" />
    </g>
  </svg>
);
export default SVGComponent;