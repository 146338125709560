import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
// import Navigationbar from '../components/navbar';
import ServiceDetails from '../components/services-top-details';
import Banner from '../components/page-banner';
import Footer from '../components/footer';
import { Data } from '../components/Data';
import Experts from '../components/experts';
import ContactCard from '../components/contact-card';
import Serwhychoose from '../components/ser-WhyChoose';
import Faqs from '../components/faqs';
import { NavbarWithMegaMenu } from "../NavbarWithMegaMenu";

import ReactBanner from '../images/react-banner2.png';
import { Helmet } from 'react-helmet-async';

export default function Magento() {
  return (
    <div className="page-super-container services-page">
      <Helmet>
            <title>Magento Development Services | Custom Solutions by Montech Design</title>
            <meta
                name="description"
                content="Montech Design offers top-notch Magento development services, providing customized solutions that blend art and engineering. Our expert Magento developers deliver innovative e-commerce solutions to help your business thrive in a competitive landscape."
            />
            <meta
                name="keywords"
                content="Magento development, Adobe Magento services, Magento 1 development, Magento 2 development, custom Magento solutions, e-commerce development, online store development, Magento store customization, Montech Design"
            />
        </Helmet>
      {/* <Navigationbar /> */}
      <NavbarWithMegaMenu />
      <Banner page="Magento" bannerimg={ReactBanner} />

      <div>
        {
          Data.magentopage.map(
            (value) => {
              return <ServiceDetails
                Heading={value.Heading}
                para={value.para}
                images={value.images}
                data={Data.serMagentoCard}
              />
            }
          )
        }
      </div>

      {/* Card */}
      <div className="contact-super-container">
        <Container className="contact-main-container">
          <Row>
            <Col>
              <div className="about-main-heading">VERIFIED ADVANTAGES OF MAGENTO WEBSITES</div>
              <div className="about-sub-heading">Excellence In Magento Development With Our Dedicated And Customizable Solutions</div>
            </Col>
          </Row>
          <Row xs={1} sm={2} md={2} lg={3} className="contact-card-container">
            {
              Data.serMagentoPaheCard.map(
                (value) => {
                  return <>
                    <ContactCard contactIcon={value.aboutcardtIcon} contactHeading={value.aboutcardheading} contactPara={value.aboutcardpara} />
                  </>
                }
              )
            }
          </Row>
        </Container>
      </div>

      <Experts
        serHeading="MAGENTO DEVELOPMENT SERVICES"
        sersubHeading="Innovative Magento Services for a Dynamic and Competitive Online Landscape"
        data={Data.magentoexpert}
      />
      
      <div>
        {
          Data.serWhyChooseMagento.map(
            (value) => {
              return <Serwhychoose
                Heading={value.Heading}
                subHeading={value.subHeading}
                para1={value.para1}
                para2={value.para2}
                para3={value.para3}
                images={value.images}
              />
            }
          )
        }
      </div>

      <Faqs
        faqHeading="FREQUENTLY ASKED QUESTIONS FOR MAGENTO DEVELOPMENT SERVICES"
        faqsubHeading="Discover Answers to Common Queries Regarding Magento Development Services"
        data={Data.magentoFaqs}
      />

      <Footer />

    </div>
  )
}
