import React from 'react'; // Import React
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';

import { Link } from "react-router-dom";

function Banner({page, bannerimg}) {
    return (
        <div className="banner-img">
            <img src={bannerimg} alt="banner-img" />
            <div className="banner-img-overly"></div>
            <div className="banner-bann-contents">
                <div className="banner-heading">
                    {page}
                </div>
                <div className="banner-sub-link-heading">
                    <Nav.Link as={Link} to="/">
                        <div className="banner-first-link">Home</div>
                    </Nav.Link>
                    <div className="banner-line-link-bet"></div>
                    <div className="banner-second-link">{page}</div>
                </div>
            </div>
        </div>
    );
}

export default Banner;


