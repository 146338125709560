import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { TbPointFilled } from "react-icons/tb";
import axios from "axios";

function CarrerAccordion() {
    const [carrerData, setcarrerData] = useState([]);

    const fetchCarrerData = async () => {
        try {
            const response = await axios.get("/Data.json");
            setcarrerData(response.data.CareerData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchCarrerData();
    }, []);
    return (
        <>
        <div className="carrer-accordion-super-container">
            <Accordion className="faq-accordion-container">
                {carrerData.map((value, index) => (
                    <Accordion.Item
                        key={index}
                        eventKey={index.toString()}
                        className="faq-accordion-item"
                    >
                        <Accordion.Header className="carrer-accordion-main">
                            <div className="carrer-accordion-main-with-button">
                                <div className="carrer-accordion-subsection-main">
                                    <div className="faq-accordion-line">
                                        {value.heading}
                                    </div>
                                    <div className="carrer-accordion-line-bottom-section">
                                        <div className="carrer-accordion-line-bottom-section-content">
                                            <div className="carrer-accordion-line-bottom-sec-heading">
                                                Vacancy: {value.vacancy}
                                            </div>
                                            <div className="carrer-accordion-line-bottom-sec-line-between">
                                                |
                                            </div>
                                            <div className="carrer-accordion-line-bottom-sec-heading">
                                                Exp. {value.exp}
                                            </div>
                                            <div className="carrer-accordion-line-bottom-sec-line-between">
                                                |
                                            </div>

                                            <div className="carrer-accordion-line-bottom-sec-heading">
                                                Location: {value.location}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carrer-btn-main">
                                    <div className="carrer-apply-btn-main">
                                        <button className="carrer-btn">
                                            Read More
                                        </button>
                                    </div>
                                    <div className="carrer-apply-btn-main">
                                        <a href="mailto:hr@montechdesign.com">
                                            <button className="carrer-btn">
                                                Apply
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Header>
                        {/* <Accordion.Body className="faq-accordion-para" key={index}>
                            {value.carrerBody && value.carrerBody.map((section, sectionIndex) => (
                                <div key={sectionIndex} className="carrer-accordion-body-main">
                                    <div className="carrer-accordion-body-heading">
                                        {section.bodyHeading}
                                    </div>
                                    <div className="carrer-accordion-body-sub-section">
                                        {section.headingPoints && section.headingPoints.map(
                                            (point, pointIndex) => (
                                                <div index={pointIndex} className="carrer-accordion-body-para-main">
                                                    <div className="carrer-accordion-body-para-icon">
                                                        <TbPointFilled />
                                                    </div>
                                                    <div className="carrer-accordion-body-para-content">
                                                        {point}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            ))}
                        </Accordion.Body> */}
                        <Accordion.Body className="faq-accordion-para">
                            {value.careerBody &&
                                value.careerBody.map(
                                    (section, sectionIndex) => (
                                        <div
                                            key={sectionIndex}
                                            className="carrer-accordion-body-main"
                                        >
                                            <div className="carrer-accordion-body-heading">
                                                {section.bodyHeading}
                                            </div>
                                            <div className="carrer-accordion-body-sub-section">
                                                {section.headingPoints &&
                                                    section.headingPoints.map(
                                                        (point, pointIndex) => (
                                                            <div
                                                                key={pointIndex}
                                                                className="carrer-accordion-body-para-main"
                                                            >
                                                                <div className="carrer-accordion-body-para-icon">
                                                                    <TbPointFilled />
                                                                </div>
                                                                <div className="carrer-accordion-body-para-content">
                                                                    {point}
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                        </div>
                                    )
                                )}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            </div>
        </>
    );
}

export default CarrerAccordion;
