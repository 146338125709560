import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import Navigationbar from "../components/navbar";
import Banner from "../components/page-banner";
import CarrerAccordion from "../components/carrerAccordion";
import Footer from "../components/footer";
import Title from "../components/title";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IoMdCheckboxOutline } from "react-icons/io";
import ReactBanner from "../images/react-banner2.png";
import LifeAt from "../images/life-at.jpg";
import { NavbarWithMegaMenu } from "../NavbarWithMegaMenu";
import { Helmet } from "react-helmet-async";

function Carrer() {
    return (
        <div className="page-super-container services-page">
            <Helmet>
            <title>Career Opportunities at Montech Design | Join Our Innovative Team</title>
            <meta
                name="description"
                content="Explore exciting career opportunities at Montech Design. Join our innovative team of professionals and take your career to the next level. We offer a dynamic work environment where creativity and growth are fostered."
            />
            <meta
                name="keywords"
                content="career opportunities, jobs at Montech Design, join our team, innovative careers, professional growth, work at Montech Design, job openings, careers in design and development, IT jobs, creative jobs, dynamic work environment"
            />
        </Helmet>
            {/* <Navigationbar /> */}
            <NavbarWithMegaMenu />
            <Banner page="Career" bannerimg={ReactBanner} />

            <div className="carrer-main">
                <div className="carrer-max-width-container">
                    <Title
                        titleName={"WE ARE HIRING"}
                        subTitle={"Current Openings"}
                    />
                    <div className="career-accordion">
                        <CarrerAccordion />
                    </div>
                </div>
            </div>

            <div className="career-share-cv-main">
                <div className="career-share-cv-heading">
                Be part of our team; please share your CV :{" "}
                    <a
                        href="mailto:hr@montechdesign.com"
                        className="career-share-link"
                    >
                        hr@montechdesign.com
                    </a>
                </div>
            </div>

            <div className="career-life-main">
                <Container className="career-life-main-container">
                    <Row xs={1} md={1} lg={2}>
                        <Col className="about-content-col">
                            <div className="about-content-heading">
                                <div className="about-main-heading">
                                    VIBRANT CULTURE
                                </div>
                                <div className="career-life-sub-heading">
                                    Life at Montech Design
                                </div>
                                <div className="carrer-life-body-main">
                                    <div className="carrer-life-body-sub-section">
                                        <div className="carrer-life-body-para-main">
                                            <div className="carrer-life-body-para-icon">
                                                <IoMdCheckboxOutline />
                                            </div>
                                            <div className="carrer-life-body-para-content">
                                                5.5 day a Week
                                            </div>
                                        </div>
                                        <div className="carrer-life-body-para-main">
                                            <div className="carrer-life-body-para-icon">
                                                <IoMdCheckboxOutline />
                                            </div>
                                            <div className="carrer-life-body-para-content">
                                                Flexible Timings
                                            </div>
                                        </div>
                                        <div className="carrer-life-body-para-main">
                                            <div className="carrer-life-body-para-icon">
                                                <IoMdCheckboxOutline />
                                            </div>
                                            <div className="carrer-life-body-para-content">
                                                Trainee Session and Monthly
                                                Events
                                            </div>
                                        </div>
                                        <div className="carrer-life-body-para-main">
                                            <div className="carrer-life-body-para-icon">
                                                <IoMdCheckboxOutline />
                                            </div>
                                            <div className="carrer-life-body-para-content">
                                                Cultural Celebration, Picnics,
                                                Sport Events
                                            </div>
                                        </div>
                                        <div className="carrer-life-body-para-main">
                                            <div className="carrer-life-body-para-icon">
                                                <IoMdCheckboxOutline />
                                            </div>
                                            <div className="carrer-life-body-para-content">
                                                Leave Encashment
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <img
                                className="career-life-images about-page-first"
                                src={LifeAt}
                                alt="about"
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            <Footer />
        </div>
    );
}

export default Carrer;
