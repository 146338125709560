import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useLocation, useNavigate } from "react-router-dom";
// import Navigationbar from '../components/navbar';
import Banner from "../components/page-banner";
import ReactBanner from "../images/react-banner2.png";
import Footer from "../components/footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import Col from 'react-bootstrap/Col';
import { MdSkipNext, MdSkipPrevious } from "react-icons/md";
import { RiShareBoxLine } from "react-icons/ri";
import PortfolioButton from "../components/portfolioButton";
import { NavbarWithMegaMenu } from "../NavbarWithMegaMenu";
import { Helmet } from "react-helmet-async";

function PortfolioDetails() {
  const { id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const category = queryParams.get("category");
  const navigate = useNavigate();

  const [portfolioData, setPortfolioData] = useState([]);

  const fetchPortfolioData = async () => {
    try {
      const response = await axios.get("/Data.json");
      setPortfolioData(response?.data?.PortfolioData);
      // setPortfolioData(response.data.PortfolioData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPortfolioData();
  }, []);

  const filteredPortfolio =
    category === "All"
      ? portfolioData
      : portfolioData.filter((portfolio) => portfolio?.category === category);
  const portfolioIndex = filteredPortfolio.findIndex(
    (item) => item?.id === parseInt(id)
  );

  if (portfolioIndex === -1) {
    return <div></div>;
  }

  const goToNextPortfolio = () => {
    const nextIndex = (portfolioIndex + 1) % filteredPortfolio?.length;
    navigate(
      `/portfolio/${filteredPortfolio[nextIndex].id}?category=${category}`
    );
  };

  const goToPreviousPortfolio = () => {
    const previousIndex =
      (portfolioIndex - 1 + filteredPortfolio.length) %
      filteredPortfolio.length;
    navigate(
      `/portfolio/${filteredPortfolio[previousIndex].id}?category=${category}`
    );
  };

  const portfolio = filteredPortfolio[portfolioIndex];

  return (
    <div className="page-super-container portfolio-page">
      <Helmet>
            <title>Portfolio | Montech Design</title>
            <meta
                name="description"
                content="Explore our diverse portfolio showcasing innovative projects across development, UI/UX design, logos, and graphics design. Montech Design is dedicated to delivering exceptional solutions that elevate your brand."
            />
            <meta
                name="keywords"
                content="portfolio, Montech Design, web development, UI/UX design, logo design, graphic design, case studies, innovative solutions"
            />
        </Helmet>
      {/* <Navigationbar /> */}
      <NavbarWithMegaMenu />
      <Banner page="Portfolio" bannerimg={ReactBanner} />
      <Container className="portfolio-max-width">
        <Row>
          {/* <Col sm={12} md={8}> */}
          <div className="portfolio-detail-image-container">
            <img
              className="portfolio-Details-img"
              src={portfolio?.image}
              alt="poerfolio"
            />
          </div>
          {/* </Col> */}
          {/* <Col sm={12} md={4}> */}
          <div class="portfolio-item-title-contant">
            <div class="portfolio-item-title">{portfolio?.title}</div>
          </div>

          <div className="portfolio-item-para-contant">
            <div className="portfolio-item-para">
              <span class="dropcap">{portfolio?.start}</span>
              {portfolio?.description}
            </div>
            <div className="portfolio-item-para">{portfolio?.description2}</div>
          </div>

          <div className="portfolio-item-categoty-contant">
            <div className="portfolio-item-categoty">
              <span class="category-span">Category : </span>
              {portfolio?.category}
            </div>
          </div>
          {/* </Col> */}
        </Row>

        <div className="portfolio-footer-divider"></div>

        <div className="portfolio-footer-container">
          <div className="portfolio-website-button">
            {portfolio?.website && (
              <PortfolioButton
                btnText={
                  <>
                    {/* <span>Visit</span>{" "}
                                        <RiShareBoxLine size={16} /> */}
                    Visit <RiShareBoxLine size={16} />
                    {/* <span>
                                            <RiShareBoxLine size={16} />
                                        </span> */}
                  </>
                }
                btnLink={portfolio?.website}
              />
            )}
          </div>

          <div className="portfolio-button-container">
            <button
              className="portfolio-button"
              onClick={goToPreviousPortfolio}
            >
              <MdSkipPrevious size={40} />
            </button>
            <button className="portfolio-button" onClick={goToNextPortfolio}>
              <MdSkipNext size={40} />
            </button>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default PortfolioDetails;
