import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Card, Nav } from 'react-bootstrap';

import { Link } from "react-router-dom";

function ServiceCard({ cardIcon, cardHeading, cardHeading2, cardPara, serviceItem1, serviceItem2, serviceItem3, serviceLink1, serviceLink2, serviceLink3 }) {
    return (
        <Col className="container-fluid mt-4">
            <Card className="about-core-card">
                <div
                    className="about-core-img"
                    dangerouslySetInnerHTML={{ __html: cardIcon }} />
                <Card.Body className="about-core-main-content">
                    <Card.Title className="about-core-heading">{cardHeading}</Card.Title>
                    <Card.Title className="about-core-heading">{cardHeading2}</Card.Title>
                    <Card.Text className="about-core-para">{cardPara}</Card.Text>
                    
                    <Nav.Link as={Link} to={serviceLink1}>
                        <div className="service-btn-main">
                            <div className="service-arrow-icon">
                                <svg version="1.0" width="20px" height="20px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#0089cf" stroke="none">
                                        <path d="M3535 3821 c-104 -47 -153 -175 -106 -276 13 -26 143 -163 380 -400 199 -198 361 -363 361 -367 0 -5 -857 -8 -1903 -8 l-1904 0 -44 -23 c-68 -36 -103 -93 -107 -175 -4 -81 21 -138 81 -186 l39 -31 1919 -3 c1055 -1 1919 -6 1919 -10 0 -4 -164 -171 -365 -372 -394 -394 -403 -405 -393 -494 7 -60 50 -130 100 -163 56 -37 148 -44 204 -15 50 26 1145 1124 1170 1172 24 49 23 134 -4 185 -30 59 -1126 1150 -1174 1170 -50 20 -124 19 -173 -4z" />
                                    </g>
                                </svg>
                            </div>
                            <div className="service-item-link">
                                {serviceItem1}
                            </div>
                        </div>
                    </Nav.Link>

                    <Nav.Link as={Link} to={serviceLink2}>
                        <div className="service-btn-main">
                            <div className="service-arrow-icon">
                                <svg version="1.0" width="20px" height="20px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#0089cf" stroke="none">
                                        <path d="M3535 3821 c-104 -47 -153 -175 -106 -276 13 -26 143 -163 380 -400 199 -198 361 -363 361 -367 0 -5 -857 -8 -1903 -8 l-1904 0 -44 -23 c-68 -36 -103 -93 -107 -175 -4 -81 21 -138 81 -186 l39 -31 1919 -3 c1055 -1 1919 -6 1919 -10 0 -4 -164 -171 -365 -372 -394 -394 -403 -405 -393 -494 7 -60 50 -130 100 -163 56 -37 148 -44 204 -15 50 26 1145 1124 1170 1172 24 49 23 134 -4 185 -30 59 -1126 1150 -1174 1170 -50 20 -124 19 -173 -4z" />
                                    </g>
                                </svg>
                            </div>
                            <div className="service-item-link">
                                {serviceItem2}
                            </div>
                        </div>
                    </Nav.Link>
                    <Nav.Link as={Link} to={serviceLink3}>
                        <div className="service-btn-main">
                            <div className="service-arrow-icon">
                                <svg version="1.0" width="20px" height="20px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#0089cf" stroke="none">
                                        <path d="M3535 3821 c-104 -47 -153 -175 -106 -276 13 -26 143 -163 380 -400 199 -198 361 -363 361 -367 0 -5 -857 -8 -1903 -8 l-1904 0 -44 -23 c-68 -36 -103 -93 -107 -175 -4 -81 21 -138 81 -186 l39 -31 1919 -3 c1055 -1 1919 -6 1919 -10 0 -4 -164 -171 -365 -372 -394 -394 -403 -405 -393 -494 7 -60 50 -130 100 -163 56 -37 148 -44 204 -15 50 26 1145 1124 1170 1172 24 49 23 134 -4 185 -30 59 -1126 1150 -1174 1170 -50 20 -124 19 -173 -4z" />
                                    </g>
                                </svg>
                            </div>
                            <div className="service-item-link">
                                {serviceItem3}
                            </div>
                        </div>
                    </Nav.Link>
                </Card.Body>
            </Card>
        </Col>
    );
}

export default ServiceCard;
