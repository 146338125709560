import React from "react";
import {
    Navbar,
    Collapse,
    Typography,
    // Button,
    IconButton,
    List,
    ListItem,
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";
import {
    ChevronDownIcon,
    Bars3Icon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import logo from "./images/montech-design.png";
import { IoEarth } from "react-icons/io5";
import { HiOutlineComputerDesktop } from "react-icons/hi2";
import { TbDeviceMobileCode } from "react-icons/tb";
import { AiOutlineShoppingCart } from "react-icons/ai";
import {
    FaReact,
    FaPhp,
    FaWordpress,
    FaApple,
    FaShopify,
    FaMagento,
} from "react-icons/fa6";
import { TbBrandAndroid } from "react-icons/tb";
import GrapgicsIcon from "./images/GrapgicsIcon";
import UiuxIcon from "./images/UiuxIcon";
import WebDesignIcon from "./images/WebDesignIcon";

const navListMenuItemsDevelopment = [
    {
        title: "Website Development",
        // description: "Find the perfect solution for your needs.",
        icon: IoEarth,
        subMenuItems: [
            {
                menu: "ReactJS Development",
                link: "/react-js",
                subMenuIcon: FaReact,
            },
            {
                menu: "PHP Development",
                link: "/php",
                subMenuIcon: FaPhp,
            },
        ],
    },
    {
        title: "CMS Development",
        // description: "Meet and learn about our dedication.",
        icon: HiOutlineComputerDesktop,
        subMenuItems: [
            {
                menu: "WordPress Development",
                link: "/wordpress",
                subMenuIcon: FaWordpress,
            },
        ],
    },
    {
        title: "Mobile App Development",
        // description: "Find the perfect solution for your needs.",
        icon: TbDeviceMobileCode,
        subMenuItems: [
            {
                menu: "Android Development",
                link: "/android",
                subMenuIcon: TbBrandAndroid,
            },
            {
                menu: "IOS Development",
                link: "/ios",
                subMenuIcon: FaApple,
            },
        ],
    },
    {
        title: "E-Commerce Solutions",
        // description: "Learn how we can help you achieve goals.",
        icon: AiOutlineShoppingCart,
        subMenuItems: [
            {
                menu: "Shopify Development",
                link: "/shopify",
                subMenuIcon: FaShopify,
            },
            {
                menu: "Magento Development",
                link: "/magento",
                subMenuIcon: FaMagento,
            },
        ],
    },
];

const navListMenuItemsDesign = [
    {
        title: "Website Design",
        // description: "Find the perfect solution for your needs.",
        link: "/website-design",
        icon: WebDesignIcon,
    },
    {
        title: "UI/UX Design",
        // description: "Meet and learn about our dedication",
        link:"/uiux-design",
        icon: UiuxIcon,
    },
    {
        title: "Graphics Design",
        // description: "Find the perfect solution for your needs.",
        link:"/graphics",
        icon: GrapgicsIcon,
    },
];

function NavListMenuDevelopment() {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
    const renderItems = navListMenuItemsDevelopment.map(
        ({ icon, title, description, subMenuItems }, key) => (
            <div className="" key={key}>
                <MenuItem className="flex items-center gap-3 rounded-lg cursor-default hover:bg-white">
                    <div className="flex items-center justify-center rounded-lg bg-blue-gray-50 p-2">
                        {" "}
                        {React.createElement(icon, {
                            strokeWidth: 2,
                            className: "h-6 w-6 text-[#0089CF]",
                        })}
                    </div>
                    <div>
                        <Typography
                            variant="h6"
                            color="blue-gray"
                            className="flex items-center text-sm font-bold mb-1 text-[#0089CF]"
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="paragraph"
                            className="text-xs !font-medium text-blue-gray-500 mb-0"
                        >
                            {description}
                        </Typography>
                    </div>
                </MenuItem>
                {subMenuItems.map((value, index) => (
                    <Link to={value.link} key={index}>
                        <MenuItem className="flex items-center gap-3 rounded-lg">
                            <div className="flex items-center justify-center rounded-lg !bg-blue-gray-50 p-2">
                                {" "}
                                {React.createElement(value.subMenuIcon, {
                                    strokeWidth: 2,
                                    className: "h-5 text-gray-900 w-5",
                                })}
                            </div>
                            <div>
                                <Typography
                                    as={Link}
                                    to={value.link}
                                    variant="h6"
                                    color="blue-gray"
                                    className="flex items-center text-sm font-medium decoration-white"
                                >
                                    {value.menu}
                                </Typography>
                            </div>
                        </MenuItem>
                    </Link>
                ))}
            </div>
        )
    );

    return (
        <React.Fragment>
            <Menu
                open={isMenuOpen}
                handler={setIsMenuOpen}
                offset={{ mainAxis: 20 }}
                placement="bottom"
                allowHover={true}
            >
                <MenuHandler>
                    <Typography
                        as="div"
                        variant="large"
                        className="font-medium"
                    >
                        <ListItem
                            className="flex items-center gap-2 py-2 pr-4 font-semibold text-gray-900"
                            selected={isMenuOpen || isMobileMenuOpen}
                            onClick={() => setIsMobileMenuOpen((cur) => !cur)}
                        >
                            Development
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`hidden h-3 w-3 transition-transform lg:block ${
                                    isMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`block h-3 w-3 transition-transform lg:hidden ${
                                    isMobileMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                        </ListItem>
                    </Typography>
                </MenuHandler>
                <MenuList className="hidden max-w-screen-2xl rounded-xl lg:block mt-2">
                    <ul className="grid grid-cols-4 gap-y-3 outline-none outline-0 pl-0 mb-0">
                        {renderItems}
                    </ul>
                </MenuList>
            </Menu>
            <div className="block lg:hidden">
                <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
            </div>
        </React.Fragment>
    );
}

function NavListMenuDesign() {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
    const renderItems = navListMenuItemsDesign.map(
        ({ icon, title, description, link }, key) => (
            <Link to={link} key={key}>
                <MenuItem className="flex items-center gap-3 rounded-lg">
                    <div className="flex items-center justify-center rounded-lg bg-blue-gray-50 p-2">
                        {" "}
                        {React.createElement(icon, {
                            strokeWidth: 2,
                            className: "h-6 w-6 text-[#0089CF]",
                        })}
                    </div>
                    <div>
                        <Typography
                            variant="h6"
                            color="blue-gray"
                            className="flex items-center text-sm font-bold mb-1 underline decoration-white text-[#0089CF]"
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="paragraph"
                            className="text-xs !font-medium text-blue-gray-500 mb-0 underline decoration-white"
                        >
                            {description}
                        </Typography>
                    </div>
                </MenuItem>
            </Link>
        )
    );

    return (
        <React.Fragment>
            <Menu
                open={isMenuOpen}
                handler={setIsMenuOpen}
                offset={{ mainAxis: 20 }}
                placement="bottom"
                allowHover={true}
            >
                <MenuHandler>
                    <Typography
                        as="div"
                        variant="large"
                        className="font-medium"
                    >
                        <ListItem
                            className="flex items-center gap-2 py-2 pr-4 font-semibold text-gray-900"
                            selected={isMenuOpen || isMobileMenuOpen}
                            onClick={() => setIsMobileMenuOpen((cur) => !cur)}
                        >
                            Design
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`hidden h-3 w-3 transition-transform lg:block ${
                                    isMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`block h-3 w-3 transition-transform lg:hidden ${
                                    isMobileMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                        </ListItem>
                    </Typography>
                </MenuHandler>
                <MenuList className="hidden max-w-screen-xl rounded-xl lg:block mt-2">
                    <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0 pl-0 mb-0">
                        {renderItems}
                    </ul>
                </MenuList>
            </Menu>
            <div className="block lg:hidden">
                <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
            </div>
        </React.Fragment>
    );
}

function NavList() {
    return (
        <List className="mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
            <Typography
                // as="a"
                as={Link}
                to="/"
                variant="large"
                color="blue-gray"
                className="font-semibold no-underline"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">
                    Home
                </ListItem>
            </Typography>
            <NavListMenuDevelopment />
            <NavListMenuDesign />
            <Typography
                // as="a"
                as={Link}
                to="/portfolio"
                variant="large"
                color="blue-gray"
                className="font-semibold no-underline"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">
                    Portfolio
                </ListItem>
            </Typography>
            <Typography
                // as="a"
                as={Link}
                to="/about-us"
                variant="large"
                color="blue-gray"
                className="font-semibold no-underline"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">
                    About Us
                </ListItem>
            </Typography>
            <Typography
                // as="a"
                as={Link}
                to="/contact-us"
                variant="large"
                color="blue-gray"
                className="font-semibold no-underline"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">
                    Contact Us
                </ListItem>
            </Typography>
        </List>
    );
}

export function NavbarWithMegaMenu() {
    const [openNav, setOpenNav] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setOpenNav(false)
        );
    }, []);

    return (
        <div className="w-full shadow-md h-auto fixed overflow-auto bg-white top-0 z-[222]">
            <Navbar className="mx-auto max-w-[1212px] px-3 py-3 shadow-none border-0">
                <div className="flex items-center justify-between text-blue-gray-900">
                    <Typography
                        as="a"
                        href="/"
                        variant="h6"
                        className="mr-4 cursor-pointer py-1.5 lg:ml-2"
                    >
                        <img src={logo} alt="Logo" className="w-auto h-11" />
                    </Typography>
                    <div className="hidden lg:block">
                        <NavList />
                    </div>
                    {/* <div className="hidden gap-2 lg:flex">
                        <Button variant="text" size="sm" color="blue-gray">
                            Log In
                        </Button>
                        <Button variant="gradient" size="sm">
                            Sign In
                        </Button>
                    </div> */}
                    <IconButton
                        variant="text"
                        color="blue-gray"
                        className="lg:hidden"
                        onClick={() => setOpenNav(!openNav)}
                    >
                        {openNav ? (
                            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
                        ) : (
                            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
                        )}
                    </IconButton>
                </div>
                <Collapse open={openNav}>
                <div className="overflow-y-auto max-h-[80vh]"> {/* Add this div for scrollable menu */}
                        <NavList />
                    </div>
                    {/* <NavList /> */}
                    {/* <div className="flex w-full flex-nowrap items-center gap-2 lg:hidden">
                        <Button
                            variant="outlined"
                            size="sm"
                            color="blue-gray"
                            fullWidth
                        >
                            Log In
                        </Button>
                        <Button variant="gradient" size="sm" fullWidth>
                            Sign In
                        </Button>
                    </div> */}
                </Collapse>
            </Navbar>
        </div>
    );
}
