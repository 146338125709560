import React from "react";
import Nav from "react-bootstrap/Nav";

// import { Link } from "react-router-dom";

function PortfolioButton({ btnLink, btnText }) {
    const handleButtonClick = () => {
        window.open(btnLink, "_blank");
    };
    return (
        <div>
            <Nav.Link
                onClick={handleButtonClick}
                className="lightbox-image theme-btn btn-style-two"
            >
                <span className="txt flex gap-2 items-center">
                    {btnText}
                </span>
            </Nav.Link>
        </div>
    );
}

export default PortfolioButton;
