import React from "react";
// import BVideo from '../images/video-banner.mp4';
import ButtonColor from '../components/buttonColor';
// import Getintouch from '../images/getintouch.jpg';

function VideoBanner() {
    return (
        <div>
            <section className="home">
                {/* <div className="video-slide">
                    <img src={Getintouch} alt="get in touch" />
                </div> */}
                {/* <div className="video-slide"
                    dangerouslySetInnerHTML={{
                        __html: `<video loop muted autoplay playsinline preload="metadata">
                                <source src="${BVideo}" type="video/mp4" />
                            </video>`
                    }}>
                </div> */}
                <div className="content">
                    <p>Feel Free to Contact Us</p>
                    <h1>We'll Be Glad To<br />Assist You!</h1>
                    <div className="vb-btn">
                        <ButtonColor btnLink={'/about-us'} btnText={"Contact Us"} />
                    </div>
                </div>

            </section>
        </div>
    );
}

export default VideoBanner;
