import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#0089CF"
      stroke="none"
    >
      <path d="M864 5096 c-57 -27 -110 -84 -124 -136 -6 -19 -10 -160 -10 -312 l0 -278 -233 0 -234 0 -62 -31 c-72 -36 -124 -97 -146 -172 -23 -76 -23 -3028 0 -3104 22 -75 74 -136 146 -172 l62 -31 284 0 c270 0 284 1 303 20 30 30 27 83 -6 109 -25 20 -39 21 -289 21 -256 0 -263 1 -296 23 -67 45 -69 55 -69 415 l0 322 2370 0 2370 0 0 -322 c0 -355 -2 -367 -63 -412 l-28 -21 -1861 -5 -1860 -5 -19 -24 c-25 -30 -24 -62 2 -95 l20 -26 407 -2 407 -3 0 -200 0 -200 -49 -7 c-166 -23 -304 -173 -314 -339 -4 -62 -2 -67 23 -87 l28 -22 937 0 937 0 28 22 c26 20 27 25 23 88 -9 165 -148 315 -316 338 l-47 7 0 200 0 200 845 5 845 5 54 30 c64 35 116 99 136 168 23 76 23 3028 0 3104 -22 75 -74 136 -146 172 -112 56 -199 37 -199 -43 0 -41 37 -76 80 -76 37 0 92 -39 112 -80 17 -33 18 -107 18 -1127 l0 -1093 -280 0 -280 0 0 1150 0 1150 74 0 c91 0 126 21 126 76 0 58 -26 74 -120 74 l-80 0 0 278 c0 152 -4 293 -10 312 -14 52 -67 109 -124 136 l-51 24 -1635 0 -1635 0 -51 -24z m3309 -137 c14 -6 30 -22 36 -36 8 -16 11 -164 11 -468 l0 -445 -1301 0 c-969 0 -1306 -3 -1322 -12 -50 -26 -60 -69 -26 -112 l20 -26 1315 0 1314 0 0 -970 0 -970 -1670 0 -1670 0 0 970 0 970 193 0 c182 0 195 1 212 20 25 28 23 76 -4 103 -20 21 -31 22 -211 25 l-190 3 0 444 c0 464 2 483 44 503 31 15 3216 16 3249 1z m-3443 -1889 l0 -1150 -270 0 -270 0 0 1093 c0 1218 -5 1135 69 1184 33 22 42 23 252 23 l219 0 0 -1150z m2300 -2415 l0 -205 -470 0 -470 0 0 205 0 205 470 0 470 0 0 -205z m264 -390 c28 -17 59 -48 73 -73 l25 -42 -831 0 -832 0 12 23 c28 52 52 77 101 101 l53 26 676 -2 676 -3 47 -30z" />
      <path d="M1343 4778 c-35 -39 -273 -464 -273 -487 0 -12 11 -33 25 -46 l24 -25 279 0 c278 0 279 0 300 23 12 13 22 36 22 51 0 30 -248 471 -278 494 -26 20 -76 15 -99 -10z m116 -309 c29 -50 50 -93 48 -95 -2 -3 -54 -3 -115 -2 l-110 3 55 97 c30 54 59 96 63 93 4 -3 31 -46 59 -96z" />
      <path d="M2052 4687 c-29 -31 -29 -77 1 -105 l23 -22 680 0 681 0 21 23 c29 31 29 77 -1 105 l-23 22 -680 0 -681 0 -21 -23z" />
      <path d="M2080 4383 c-46 -17 -64 -68 -40 -113 19 -35 64 -40 359 -40 307 0 323 3 350 51 20 37 0 84 -41 98 -32 11 -599 15 -628 4z" />
      <path d="M1112 3630 c-12 -5 -27 -21 -32 -35 -15 -40 -13 -1437 2 -1470 7 -16 24 -29 47 -36 24 -7 274 -9 743 -7 l707 3 23 23 23 23 0 730 0 731 -28 24 -28 24 -717 -1 c-406 0 -727 -4 -740 -9z m1358 -541 l0 -401 -31 7 c-60 13 -154 -14 -243 -70 -99 -62 -133 -75 -196 -75 -63 0 -140 40 -258 134 -173 136 -287 170 -447 131 -33 -8 -63 -14 -67 -15 -10 0 -11 674 -1 683 3 4 285 7 625 7 l618 0 0 -401z m-982 -425 c29 -10 86 -44 134 -81 192 -148 264 -183 378 -183 91 0 160 23 254 83 73 47 86 52 148 55 l68 4 0 -151 0 -151 -625 0 -625 0 2 193 3 192 39 19 c79 38 148 44 224 20z" />
      <path d="M1989 3307 c-100 -28 -159 -101 -167 -207 -5 -73 8 -115 53 -168 44 -52 99 -76 176 -77 56 0 73 4 115 30 127 79 152 252 50 357 -61 63 -145 87 -227 65z m120 -173 c27 -35 26 -66 -2 -100 -31 -35 -77 -37 -112 -4 -51 48 -16 130 55 130 30 0 43 -6 59 -26z" />
      <path d="M2870 3567 c-41 -14 -59 -52 -44 -94 18 -53 16 -53 617 -53 589 0 604 1 621 45 18 49 -4 91 -55 105 -52 15 -1095 12 -1139 -3z" />
      <path d="M2846 3219 c-34 -27 -36 -77 -3 -107 l23 -22 581 0 582 0 20 26 c28 35 26 69 -4 99 l-24 25 -574 0 c-566 0 -575 0 -601 -21z" />
      <path d="M2863 2895 c-55 -24 -57 -105 -3 -132 34 -17 1111 -19 1159 -2 70 25 69 111 -3 139 -18 6 -219 10 -574 10 -446 -1 -553 -3 -579 -15z" />
      <path d="M2846 2549 c-34 -27 -36 -77 -3 -107 l23 -22 578 0 577 0 24 25 c14 13 25 36 25 50 0 14 -11 37 -25 50 l-24 25 -574 0 c-566 0 -575 0 -601 -21z" />
      <path d="M2863 2225 c-60 -26 -57 -106 3 -131 30 -13 84 -15 306 -12 270 3 270 3 289 27 36 44 21 102 -32 120 -50 17 -525 14 -566 -4z" />
      <path d="M2529 1606 c-84 -30 -143 -101 -155 -187 -18 -131 93 -259 226 -259 52 0 134 35 167 71 34 37 63 110 63 159 0 52 -35 134 -71 167 -61 55 -156 76 -230 49z m129 -172 c12 -14 22 -31 22 -39 0 -30 -24 -64 -53 -76 -37 -15 -75 2 -95 44 -16 34 0 75 38 94 31 16 59 8 88 -23z" />
    </g>
  </svg>
);
export default SVGComponent;
