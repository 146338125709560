import React, { Component } from "react";
import Slider from "react-slick";
import Title from "./title";

import { Data } from "./Data";

export default class Logoslider extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        const settings = {
            className: "center",
            centerMode: false,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 8,
            speed: 1000,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 6000,
            pauseOnHover: false,
            responsive: [
                {
                    breakpoint: 2560,
                    settings: {
                        slidesToShow: 8,
                        slidesToScroll: 1,
                        infinite: true
                    }
                },
                // {
                //     breakpoint: 2100,
                //     settings: {
                //         slidesToShow: 2,
                //         slidesToScroll: 2,
                //         infinite: true
                //     }
                // },
                // {
                //     breakpoint: 1440,
                //     settings: {
                //         slidesToShow: 2,
                //         slidesToScroll: 2,
                //         infinite: true
                //     }
                // },
                // {
                //     breakpoint: 1024,
                //     settings: {
                //         slidesToShow: 2,
                //         slidesToScroll: 2,
                //         infinite: true,
                //     }
                // },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        infinite: true
                        // centerPadding: "60px"
                    }
                },
                // {
                //     breakpoint: 768,
                //     settings: {
                //         slidesToShow: 1,
                //         slidesToScroll: 1,
                //         infinite: true,
                //     }
                // },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        infinite: true,
                        centerPadding: "30px",
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        infinite: true
                    }
                },
                // {
                //     breakpoint: 376,
                //     settings: {
                //         slidesToShow: 1,
                //         slidesToScroll: 1,
                //         initialSlide: 1,
                //         infinite: true
                //     }
                // }
            ]
        };
        return (
            <div className="main-logo-container">
                
                <div className="sub-logo-container">
                <Title titleName={'OUR'} subTitle={'Technology Stack'} />
                    <Slider className="slider-container" ref={c => (this.slider = c)} {...settings}>
                        {
                            Data.logo.map(
                                (value, index) => {
                                    return <div key={index} className="product-slider">
                                        <img src={value.logoImg} alt="product" />
                                    </div>
                                }
                            )
                        }
                    </Slider>
                </div>
            </div>
        );
    }
}