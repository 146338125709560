import { Col, Card, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function ContactCard({ contactIcon, contactHeading, contactPara, contactLink }) {
    return (
        <Col className="container-fluid mt-4">
            <Nav.Link href={contactLink} target="_blank" className="contant-card-link">
                <Card className="contact-card">
                    <div
                        className="contact-img ser-contant-img"
                        dangerouslySetInnerHTML={{ __html: contactIcon }} />
                    <Card.Body className="contact-main-content">
                        <Card.Title className="contact-heading">{contactHeading}</Card.Title>
                        <Card.Text className="contact-para">{contactPara}</Card.Text>
                        {/* <Card.Text className="contact-para">{contactPara2}</Card.Text> */}
                    </Card.Body>
                </Card>
            </Nav.Link>
        </Col>
    );
}

export default ContactCard;

