import React, { Component } from "react";
import Slider from "react-slick";
import { Row, Col, Card } from 'react-bootstrap';
import { Data } from "./Data";
import Button from './button';

export default class Testinew extends Component {
    render() {
        const settings = {
            className: "center",
            centerMode: false,
            dots: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 2,
            speed: 1000,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            pauseOnHover: false,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        infinite: true,
                        centerPadding: "30px",
                    }
                }
            ]
        };
        return (
            <div className="testimonials-new">
                <Row className="testimonials-new-sub">
                    <Col lg={4}>
                        <div className="testi-heading">
                            <div className="test-first-main-heading">TESTIMONIALS</div>
                            <div className="testi-first-sub-heading">What People<br></br>Say About Montech</div>
                            <div className="testi-first-sub-para">We always connected dots across ecosystems</div>
                            <div className="testi-btn-container">
                                <Button btnText="Get in Touch" btnLink="/contact-us" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <Slider className="slider-container" ref={c => (this.slider = c)} {...settings}>
                            {/* {
                                Data.testimonials.map(
                                    (value) => {
                                        return <div key={value.prodkey} className="product-slider">
                                            <Card className="testimonial-card">
                                                <Card.Body className="testimonial-main-content">
                                                    <div className="testimonial-heading-part">
                                                        <Card.Title className="testimonial-heading">{value.testiname}</Card.Title>
                                                        <Card.Title className="testimonial-sub-heading">{value.testicompany}</Card.Title>
                                                    </div>
                                                    <Card.Text className="testimonial-sub-title">{value.testidesc}</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    }
                                )
                            } */}

                            {
                                Data.testimonials.map((value,index) => {
                                    return (
                                        <div key={index} className="product-slider">
                                            <Card className="testimonial-card">
                                                <Card.Body className="testimonial-main-content">
                                                    <div className="testimonial-heading-part">
                                                        <Card.Title className="testimonial-heading">{value.testiname}</Card.Title>
                                                        <Card.Title className="testimonial-sub-heading">{value.testicompany}</Card.Title>
                                                    </div>
                                                    <Card.Text className="testimonial-sub-title">{value.testidesc}</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    );
                                })
                            }

                        </Slider>
                    </Col>
                </Row>
            </div>
        );
    }
}