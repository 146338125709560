import React from 'react';
import Nav from 'react-bootstrap/Nav';

import { Link } from "react-router-dom";

function Button({ btnLink, btnText }) {
    return (
        <div>
            <Nav.Link as={Link} to={btnLink} className="lightbox-image theme-btn btn-style-one">
                <span className="txt">{btnText}</span>
            </Nav.Link>
        </div>
    );
}

export default Button;
