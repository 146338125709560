import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import Navigationbar from "../components/navbar";
import ControlledCarousel from "../components/main-slider";
import About from "../components/about";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { Data } from "../components/Data";
import Productslider from "../components/productslider";
import VideoBanner from "../components/video-banner";
import Testimonials from "../components/testimonial";
import Footer from "../components/footer";
import Title from "../components/title";
import ServiceCard from "../components/service-card";
import ServicesImgCard from "../components/card";
import aboutPagesecondImg from "../images/about-us-page-second.jpg";
import Logoslider from "../components/logo-slider";
import { NavbarWithMegaMenu } from "../NavbarWithMegaMenu";
import { Helmet } from "react-helmet-async";

export default function Home() {
    return (
        <div>
            <Helmet>
            <title>Montech Design</title>
            
        </Helmet>
            {/* <Navigationbar /> */}
            <NavbarWithMegaMenu />
            <ControlledCarousel />
            <div>
                {Data.homeAbout.map((value, index) => {
                    return (
                        <About
                            key={index}
                            heading={value.heading}
                            subHeading={value.subHeading}
                            mainpara={value.mainpara}
                            para={value.para}
                            images={value.images}
                            btnText={value.btnText}
                            btnLink={value.btnLink}
                        />
                    );
                })}
            </div>

            {/* Service card */}
            <div className="about-card-super-container">
                <Title titleName={"OUR SERVICES"} subTitle={"What We Offer"} />
                <Container className="about-core-card-main-container">
                    <Row
                        xs={1}
                        sm={2}
                        md={2}
                        lg={4}
                        className="about-core-card-container"
                    >
                        {Data.services.map((value, index) => {
                            return (
                                <ServiceCard
                                    key={index}
                                    cardIcon={value.serviceIcon}
                                    cardHeading={value.serviceHeading}
                                    cardHeading2={value.serviceHeading2}
                                    cardPara={value.servicesPara}
                                    serviceItem1={value.serviceItem1}
                                    serviceItem2={value.serviceItem2}
                                    serviceItem3={value.serviceItem3}
                                    serviceLink1={value.serviceLink1}
                                    serviceLink2={value.serviceLink2}
                                    serviceLink3={value.serviceLink3}
                                />
                            );
                        })}
                    </Row>
                </Container>
            </div>

            <div>
                <ServicesImgCard />
            </div>

            {/* <Experts /> */}

            <Productslider />

            <VideoBanner />

            <Container className="home-page-accordion-main-container">
                <Row xs={1} md={1} lg={2} className="row-container">
                    <Col className="about-page-content-col home-page">
                        <div className="about-page-content-heading">
                            <div className="about-page-main-heading">
                                WHAT WE DO
                            </div>
                            <div className="about-page-sub-heading">
                                We Provide Flexible
                            </div>
                            <div
                                className="about-page-sub-heading"
                                style={{ marginTop: "0px" }}
                            >
                                IT Solutions
                            </div>
                            <div className="about-page-mainpara">
                                Revolutionizing software for industry leaders.
                            </div>
                            <div
                                className="about-page-mainpara"
                                style={{ marginTop: "0px" }}
                            >
                                Trusted by tech innovators worldwide.
                            </div>
                            <Accordion
                                defaultActiveKey="0"
                                className="about-page-accordion-container"
                            >
                                {Data.homePageAccordion.map((value) => {
                                    return (
                                        <Accordion.Item
                                            key={value.acckey}
                                            eventKey={value.acckey}
                                            className="about-page-accordion-item"
                                        >
                                            <Accordion.Header className="about-page-accordion-line home-accordion-line">
                                                {value.accheading}
                                            </Accordion.Header>
                                            <Accordion.Body className="about-page-accordion-para home-accordion-para">
                                                {value.accpara}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })}
                            </Accordion>
                        </div>
                    </Col>
                    <Col className="about-page-img-col">
                        <img
                            className="about-page-images"
                            src={aboutPagesecondImg}
                            alt="about"
                        />
                    </Col>
                </Row>
            </Container>

            <Testimonials />

            <Logoslider />

            <Footer />
        </div>
    );
}
