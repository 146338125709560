import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion, Row, Col } from 'react-bootstrap';

function Faqs({ faqHeading, faqsubHeading, data }) {
    return (
        <div className="faq-main">
            <div className="faq-max-width-container">
                <Row>
                    <Col>
                        <div className="about-main-heading">{faqHeading}</div>
                        <div className="about-sub-heading">{faqsubHeading}</div>
                    </Col>
                </Row>
                <Accordion defaultActiveKey="0" className="faq-accordion-container">
                    {data.map((value, index) => (
                        <Accordion.Item key={index} eventKey={index.toString()} className="faq-accordion-item">
                            <Accordion.Header className="faq-accordion-line">{value.seraccheading}</Accordion.Header>
                            <Accordion.Body className="faq-accordion-para">{value.seraccpara}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </div>
    );
}

export default Faqs;
