import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import logo from "../images/montech-design-footer.png";
import { Link } from "react-router-dom";
import { ImWhatsapp } from "react-icons/im";
import { BsSkype } from "react-icons/bs";
import {
    IconButton,
    SpeedDial,
    SpeedDialHandler,
    SpeedDialContent,
    SpeedDialAction,
} from "@material-tailwind/react";
import {
    ChatBubbleBottomCenterTextIcon,
    ChatBubbleBottomCenterIcon,
} from "@heroicons/react/24/outline";


function Footer() {
    // const actions = [
    //     {
    //         label: "WhatsApp",
    //         icon: <ImWhatsapp style={{ color: "#25D366" }} />,
    //         onClick: console.log,
    //     },
    //     {
    //         label: "Skype",
    //         icon: <BsSkype style={{ color: "#00AFF0" }} />,
    //         onClick: console.log,
    //     },
    // ];
    return (
        <div className="footer-super-container">
            <Container className="footer-main-container">
                <Row>
                    <Col className="foot-logo-container" sm={12} lg={4}>
                        <div className="foot-logo">
                            <img src={logo} alt="Logo" />
                        </div>
                        <div className="foot-para">
                            A leading Information Technology company, delivering
                            unparalleled excellence in software solutions. With
                            over 20 years of expertise, we stand as a beacon of
                            quality and reliability in the IT world.
                        </div>
                        {/* <div className="foot-para">Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.</div> */}

                        <div className="foo-follow-container">
                            <div className="foo-link-heading">Follow Us</div>
                            <div className="foo-link-line"></div>
                            <div className="foo-icon-content">
                                <Nav.Link
                                    href="https://www.linkedin.com/company/montech-design/"
                                    target="_blank"
                                >
                                    <div className="foo-icon-face">
                                        <svg
                                            version="1.0"
                                            width="23px"
                                            height="23px"
                                            viewBox="0 0 512.000000 512.000000"
                                            preserveAspectRatio="xMidYMid meet"
                                        >
                                            <g
                                                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#ffffff"
                                                stroke="none"
                                            >
                                                <path d="M905 4598 c-136 -26 -282 -140 -344 -270 -46 -98 -60 -204 -39 -301 23 -103 60 -177 123 -245 306 -329 845 -117 845 333 0 314 -277 542 -585 483z" />
                                                <path d="M3380 3290 c-47 -10 -107 -26 -135 -35 -167 -58 -335 -186 -425 -327 l-40 -60 -2 188 -3 189 -407 3 -408 2 0 -1370 0 -1370 425 0 425 0 0 733 c0 730 5 849 36 972 37 144 125 259 236 310 114 52 297 58 412 15 111 -42 194 -145 230 -286 30 -117 36 -279 36 -1006 l0 -728 425 0 425 0 0 798 c0 825 -6 1007 -41 1202 -82 465 -318 711 -746 775 -135 20 -336 18 -443 -5z" />
                                                <path d="M580 1880 l0 -1370 425 0 425 0 0 1370 0 1370 -425 0 -425 0 0 -1370z" />
                                            </g>
                                        </svg>
                                    </div>
                                </Nav.Link>
                                <Nav.Link href="#">
                                    <div className="foo-icon-face">
                                        <svg
                                            version="1.0"
                                            width="23px"
                                            height="23px"
                                            viewBox="0 0 512.000000 512.000000"
                                            preserveAspectRatio="xMidYMid meet"
                                        >
                                            <g
                                                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#ffffff"
                                                stroke="none"
                                            >
                                                <path d="M2825 4580 c-361 -74 -599 -286 -705 -629 -39 -125 -50 -239 -50 -538 l0 -283 -272 0 c-302 0 -315 -3 -335 -60 -17 -49 -17 -671 0 -720 19 -54 44 -59 345 -59 l262 -1 0 -859 0 -860 24 -28 24 -28 359 -3 c386 -3 422 0 441 43 9 19 12 253 12 885 l0 859 335 3 c305 3 336 5 352 21 17 16 18 46 18 392 l0 374 -23 23 c-22 23 -24 23 -353 26 l-331 3 4 267 c3 287 6 301 61 353 41 39 66 43 345 48 l274 6 24 28 24 28 0 340 0 340 -25 24 -24 25 -348 -1 c-297 -1 -361 -3 -438 -19z" />
                                            </g>
                                        </svg>
                                    </div>
                                </Nav.Link>
                                <Nav.Link href="#">
                                    <div className="foo-icon-face">
                                        <svg
                                            version="1.0"
                                            width="20px"
                                            height="20px"
                                            viewBox="0 0 512.000000 512.000000"
                                            preserveAspectRatio="xMidYMid meet"
                                        >
                                            <g
                                                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#ffffff"
                                                stroke="none"
                                            >
                                                <path d="M1100 5105 c-287 -43 -514 -157 -721 -364 -213 -213 -329 -451 -368 -750 -15 -117 -15 -2745 0 -2862 39 -299 155 -537 368 -750 213 -213 451 -329 750 -368 117 -15 2745 -15 2862 0 299 39 537 155 750 368 213 213 329 451 368 750 15 117 15 2745 0 2862 -39 299 -155 537 -368 750 -213 213 -451 329 -750 368 -128 17 -2778 13 -2891 -4z m2997 -452 c113 -39 138 -52 231 -116 148 -102 265 -261 324 -440 l33 -102 0 -1435 c0 -1373 -1 -1438 -19 -1495 -67 -217 -183 -378 -354 -494 -74 -50 -138 -79 -242 -112 l-75 -24 -1435 0 -1435 0 -102 33 c-280 92 -476 297 -569 597 -18 57 -19 122 -19 1495 0 1373 1 1438 19 1495 71 228 195 394 380 510 78 49 212 101 295 115 25 4 683 7 1461 6 l1415 -1 92 -32z" />
                                                <path d="M3880 4256 c-71 -16 -112 -38 -160 -87 -65 -67 -85 -120 -85 -224 0 -78 4 -97 27 -140 55 -104 148 -165 264 -173 85 -5 130 7 201 55 199 134 176 439 -40 542 -74 35 -133 43 -207 27z" />
                                                <path d="M2396 3829 c-483 -67 -895 -403 -1045 -855 -51 -154 -65 -243 -65 -414 0 -171 14 -260 65 -415 121 -362 433 -673 797 -795 152 -50 242 -64 412 -64 170 0 260 14 412 64 364 122 676 433 797 795 51 155 65 244 65 415 0 224 -43 407 -140 590 -185 347 -503 586 -889 665 -96 20 -314 28 -409 14z m421 -458 c107 -35 136 -49 218 -104 168 -113 281 -270 346 -477 20 -65 24 -96 24 -230 0 -165 -12 -226 -71 -357 -74 -168 -249 -343 -418 -418 -130 -58 -191 -70 -356 -70 -165 0 -226 12 -356 70 -169 75 -344 250 -418 418 -59 131 -71 192 -71 357 0 134 4 165 24 230 34 108 64 171 121 254 113 164 271 277 465 334 99 29 114 31 265 27 119 -3 146 -7 227 -34z" />
                                            </g>
                                        </svg>
                                    </div>
                                </Nav.Link>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <Nav.Link
                                    href="https://goo.gl/maps/DJqVUNuSLicuQ2z89"
                                    target="_blank"
                                >
                                    <div className="foo-address-container">
                                        <div className="foo-add-icon">
                                            <svg
                                                version="1.0"
                                                width="28px"
                                                height="28px"
                                                viewBox="0 0 200.000000 200.000000"
                                                preserveAspectRatio="xMidYMid meet"
                                            >
                                                <g
                                                    transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                                                    fill="#ffffff"
                                                    stroke="none"
                                                >
                                                    <path d="M895 1940 c-250 -50 -438 -277 -438 -530 1 -163 136 -452 418 -885 54 -84 81 -116 100 -121 53 -13 73 2 142 109 284 436 423 732 423 898 0 189 -108 375 -271 467 -105 59 -259 85 -374 62z m168 -291 c65 -16 130 -69 161 -130 90 -181 -61 -394 -258 -365 -66 10 -145 63 -180 120 -44 71 -49 165 -12 241 54 110 171 164 289 134z" />
                                                    <path d="M549 621 c-88 -33 -120 -51 -172 -100 -52 -48 -70 -86 -71 -147 -2 -174 250 -304 624 -321 332 -15 637 74 729 214 39 58 45 137 16 193 -17 32 -80 91 -125 117 -34 20 -181 76 -184 71 -1 -2 -19 -29 -39 -61 -26 -39 -33 -59 -25 -61 162 -51 230 -87 250 -131 10 -21 8 -30 -8 -53 -30 -40 -130 -89 -242 -119 -90 -25 -112 -27 -302 -27 -190 0 -212 2 -302 27 -112 30 -212 79 -242 119 -16 23 -18 32 -8 53 20 44 87 79 250 131 9 2 0 23 -28 64 -22 33 -42 60 -43 60 -1 -1 -36 -14 -78 -29z" />
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="foo-add-content">
                                            <div className="foo-add-sub-row">
                                                A-422, Yash Plaza, Opp. Dhana
                                                Mill, Varachha Road,
                                            </div>
                                            <div className="foo-add-row">
                                                Surat, Gujarat, India.
                                            </div>
                                        </div>
                                    </div>
                                </Nav.Link>
                            </Col>
                            <Col lg={5}>
                                <Nav.Link href="tel:919879328385">
                                    <div className="foo-address-container">
                                        <div className="foo-add-icon">
                                            <svg
                                                version="1.0"
                                                width="30px"
                                                height="30px"
                                                viewBox="0 0 512.000000 512.000000"
                                                preserveAspectRatio="xMidYMid meet"
                                            >
                                                <g
                                                    transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                    fill="#ffffff"
                                                    stroke="none"
                                                >
                                                    <path d="M1165 4771 c-165 -95 -668 -676 -812 -938 -34 -61 -35 -67 -29 -131 22 -214 117 -521 248 -802 145 -309 390 -679 668 -1010 142 -169 475 -502 640 -642 598 -504 1201 -820 1744 -913 49 -8 102 -15 119 -15 57 0 215 102 454 292 308 246 551 486 592 586 34 81 -32 156 -271 315 -347 229 -670 411 -823 462 -71 24 -77 25 -112 10 -49 -20 -80 -51 -218 -210 -62 -71 -133 -149 -157 -173 l-45 -43 -64 16 c-208 54 -490 201 -724 378 -103 79 -277 247 -373 362 -182 217 -354 529 -423 768 l-22 78 54 55 c30 31 104 98 164 149 140 118 190 171 209 217 15 34 14 42 -5 102 -56 182 -465 871 -613 1033 -82 90 -120 101 -201 54z" />
                                                    <path d="M2720 4640 l0 -160 48 0 c208 0 484 -68 699 -170 367 -175 644 -446 823 -805 112 -225 160 -394 182 -647 l12 -138 158 0 158 0 0 44 c0 77 -19 247 -41 361 -59 309 -224 662 -420 899 -359 434 -839 697 -1387 761 -70 8 -151 15 -179 15 l-53 0 0 -160z" />
                                                    <path d="M2720 3843 l0 -160 79 -7 c233 -19 436 -116 606 -290 162 -165 252 -361 271 -588 l7 -78 158 0 159 0 0 41 c0 138 -61 383 -131 521 -122 243 -338 462 -574 580 -149 75 -315 121 -484 134 l-91 7 0 -160z" />
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="foo-add-content">
                                            <div className="foo-add-sub-row">
                                                Give Us a Call
                                            </div>
                                            <div className="foo-add-row">
                                                +91-98793-28385
                                            </div>
                                        </div>
                                    </div>
                                </Nav.Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="foo-link-container">
                                    <div className="foo-link-heading">
                                        Services
                                    </div>
                                    <div className="foo-link-line"></div>
                                    <Row className="foo-link-content">
                                        <Col>
                                            <Nav.Link
                                                as={Link}
                                                to="/react-js"
                                                className="foo-link-tab"
                                            >
                                                ReactJS Development
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="/php"
                                                className="foo-link-tab"
                                            >
                                                PHP Development
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="/wordpress"
                                                className="foo-link-tab"
                                            >
                                                WordPress Development
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="/android"
                                                className="foo-link-tab"
                                            >
                                                Android Development
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="/ios"
                                                className="foo-link-tab"
                                            >
                                                IOS Development
                                            </Nav.Link>
                                        </Col>
                                        <Col>
                                            <Nav.Link
                                                as={Link}
                                                to="/shopify"
                                                className="foo-link-tab"
                                            >
                                                Shopify Development
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="/magento"
                                                className="foo-link-tab"
                                            >
                                                Magento Development
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="/website-design"
                                                className="foo-link-tab"
                                            >
                                                Website Design
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="/uiux-design"
                                                className="foo-link-tab"
                                            >
                                                UI/UX Design
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="#"
                                                className="foo-link-tab"
                                            >
                                                Graphics Design
                                            </Nav.Link>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={5} className="foo-pages-col-section">
                                <div className="foo-link-container">
                                    <div className="foo-link-heading">
                                        Pages
                                    </div>
                                    <div className="foo-link-line"></div>
                                    <Row className="foo-link-content">
                                        <Col>
                                            <Nav.Link
                                                as={Link}
                                                to="/"
                                                className="foo-link-tab"
                                            >
                                                Home
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="/about-us"
                                                className="foo-link-tab"
                                            >
                                                About us
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="/contact-us"
                                                className="foo-link-tab"
                                            >
                                                Contact us
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="/portfolio"
                                                className="foo-link-tab"
                                            >
                                                Portfolio
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="/carrer"
                                                className="foo-link-tab"
                                            >
                                                Career
                                            </Nav.Link>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="foo-mid-line"></div>
                <Row className="foo-cpy">
                    <Col className="foo-cpy-left">
                        Copyright © 2024 Montech Design | All Rights Reserved.
                    </Col>
                </Row>
                <div className="fixed bottom-4 right-4 z-50">
                    <SpeedDial>
                        <SpeedDialHandler>
                            <IconButton
                                // color="white"
                                size="lg"
                                className="rounded-full bg-[#0089CF] shadow-xl"
                            >
                                <ChatBubbleBottomCenterTextIcon className="hidden h-5 w-5 transition-transform group-hover:block" />
                                <ChatBubbleBottomCenterIcon className="block h-5 w-5 transition-transform group-hover:hidden" />
                            </IconButton>
                        </SpeedDialHandler>
                        <SpeedDialContent className="rounded-full border border-blue-gray-50 bg-white shadow-xl shadow-black/10">
                            <a
                                href="https://wa.me/919879328385"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <SpeedDialAction className="bg-[#25D366]">
                                    <ImWhatsapp className="h-5 w-5 text-white" />
                                </SpeedDialAction>
                            </a>
                            <a
                                href="skype:montech.design?chat"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <SpeedDialAction className="bg-[#00AFF0]">
                                    <BsSkype className="h-5 w-5 text-white" />
                                </SpeedDialAction>
                            </a>
                        </SpeedDialContent>
                    </SpeedDial>
                </div>
            </Container>
        </div>
    );
}

export default Footer;
