import React from "react";
import "./App.css";
import "./AppMedia.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

// Pages
import Home from "./pages/home";
import AboutUs from "./pages/about-us";
import ContactUs from "./pages/contact-us";
import ReactJS from "./pages/react-js";
import GoToTop from "./components/gototop";
import Magento from "./pages/magento";
import PHP from "./pages/php";
import WordPress from "./pages/wordpress";
import Android from "./pages/android";
import Shopify from "./pages/shopify";
import IOS from "./pages/ios";
import Portfolio from "./pages/portfolio";
import PortfolioDetails from "./pages/PortfolioDetails";
import Carrer from "./pages/carrer";
import WebsiteDesign from "./pages/website-design";
import UiuxDesign from "./pages/uiux-design";
import Graphics from "./pages/graphics";

function App() {
  return (
    <HelmetProvider>
      <Router>
        <GoToTop />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/react-js" element={<ReactJS />} />
          <Route path="/php" element={<PHP />} />
          <Route path="/wordpress" element={<WordPress />} />
          <Route path="/android" element={<Android />} />
          <Route path="/ios" element={<IOS />} />
          <Route path="/magento" element={<Magento />} />
          <Route path="/shopify" element={<Shopify />} />
          <Route path="/website-design" element={<WebsiteDesign />} />
          <Route path="/uiux-design" element={<UiuxDesign />} />
          <Route path="/graphics" element={<Graphics />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/:id" element={<PortfolioDetails />} />
          <Route path="/carrer" element={<Carrer />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
