import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Nav from 'react-bootstrap/Nav';

import Title from './title';

import { Data } from './Data';

import { Link } from "react-router-dom";


function ServicesImgCard() {
    return (
        <div className="service-super-container">
            <Container fluid className="main-container">
                <Title titleName={'FEW REASONS'} subTitle={'Why Choose Us'} />
                <Row xs={1} sm={2} md={2} lg={3} className="g-4 card-container">
                    {
                        Data.servicesImgCard.map(
                            (value, index) => {
                                return <Col key={index} className="container-fluid mt-4">
                                    <Card className="services-card">
                                        <Card.Img className="card-img" variant="top" src={value.images} />
                                        <Card.Body className="main-content">
                                            <Card.Title className="services-heading">{value.servicesHeading}</Card.Title>
                                            <Card.Text className="services-sub-title">{value.servicesSunHeading}</Card.Text>
                                            <Nav.Link as={Link} to={value.btnLink} className="read-more-container">
                                                <div className="card-btn">
                                                    <svg version="1.0" width="20px" height="20px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#FFFFFF" stroke="none">
                                                            <path d="M3535 3821 c-104 -47 -153 -175 -106 -276 13 -26 143 -163 380 -400 199 -198 361 -363 361 -367 0 -5 -857 -8 -1903 -8 l-1904 0 -44 -23 c-68 -36 -103 -93 -107 -175 -4 -81 21 -138 81 -186 l39 -31 1919 -3 c1055 -1 1919 -6 1919 -10 0 -4 -164 -171 -365 -372 -394 -394 -403 -405 -393 -494 7 -60 50 -130 100 -163 56 -37 148 -44 204 -15 50 26 1145 1124 1170 1172 24 49 23 134 -4 185 -30 59 -1126 1150 -1174 1170 -50 20 -124 19 -173 -4z" />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </Nav.Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            }
                        )
                    }
                </Row>
            </Container>
        </div>
    );
}

export default ServicesImgCard;
