import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import { Data } from './Data';

import Button from './button';

function ControlledCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect} className="main-slider">
            {/* {
                Data.slider.map(
                    (value, index) => {
                        return <Carousel.Item interval={3000} key={index}>
                            <div className="overlay">
                                <img src={value.imageUrl} alt="slider 1" />
                            </div>
                            <Carousel.Caption className="slider-content">
                                <p>{value.subHeading}</p>
                                <h3>{value.headingFirst}<br></br>{value.headingSecond}</h3>
                                <div className="btn-container">
                                    <Button btnText={value.btnText} btnLink={value.btnLink} />
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>

                    }
                )
            } */}

            {
                Data.slider.map((value, index) => {
                    return (
                        <Carousel.Item key={index} interval={3000}>
                            <div className="overlay">
                                <img src={value.imageUrl} alt="slider 1" />
                            </div>
                            <Carousel.Caption className="slider-content">
                                <p>{value.subHeading}</p>
                                <h3>{value.headingFirst}<br></br>{value.headingSecond}</h3>
                                <div className="btn-container">
                                    <Button btnText={value.btnText} btnLink={value.btnLink} />
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    );
                })
            }
        </Carousel>
    );
}

export default ControlledCarousel;