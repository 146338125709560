/* eslint-disable jsx-a11y/anchor-is-valid */
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Serwhychoose({ Heading, subHeading, para1, para2, para3, images }) {
    return (
        <div className="serWhyChoose-main">
            <Container className="about-main-container">
                <Row xs={1} md={1} lg={2}>
                    <Col>
                        <div className="ser-detail-content-main">
                            <div className="about-main-heading">{Heading}</div>
                            <div className="about-sub-heading">{subHeading}</div>
                            <div className="ser-detail-para">{para1}</div>
                            <div className="ser-detail-para">{para2}</div>
                            <div className="ser-detail-para">{para3}</div>
                        </div>
                    </Col>
                    <Col className="ser-whydetail-img-section">
                        <img src={images} alt="react" />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Serwhychoose;