import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonColor from './buttonColor';

function About({ heading, subHeading, mainpara, para, images, btnText, btnLink }) {
  return (
    <Container className="about-main-container">
      <Row xs={1} md={1} lg={2}>
        <Col className="about-content-col">
          <div className="about-content-heading">
            <div className="about-main-heading">{heading}</div>
            <div className="about-sub-heading">{subHeading}</div>
            <div className="about-mainpara">{mainpara}</div>
            <div className="about-para">{para}</div>
            <div className="btn-container">
              <ButtonColor btnText={btnText} btnLink={btnLink} />
            </div>
          </div>
        </Col>
        <Col>
          <img className="about-images about-page-first" src={images} alt="about" />
        </Col>
      </Row>
    </Container>
  );
}

export default About;