import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#0089CF"
      stroke="none"
    >
      <path d="M76 5099 l-26 -20 0 -248 c0 -246 0 -247 26 -299 80 -161 304 -198 446 -73 74 64 78 85 78 369 0 246 0 252 -22 272 -25 23 -65 26 -91 6 -31 -23 -37 -74 -37 -294 l0 -213 -27 -25 c-58 -54 -141 -53 -197 3 l-26 26 0 224 c0 177 -3 230 -15 252 -23 46 -66 54 -109 20z" />
      <path d="M796 5099 l-26 -20 0 -325 0 -325 25 -24 c27 -28 42 -30 78 -14 44 20 47 42 47 370 l0 310 -25 24 c-30 30 -64 32 -99 4z" />
      <path d="M1391 5103 c-21 -18 -281 -617 -281 -648 0 -69 107 -97 139 -36 30 56 261 615 261 632 0 28 -44 69 -73 69 -14 0 -34 -8 -46 -17z" />
      <path d="M1705 5095 l-25 -24 0 -233 c0 -136 5 -249 11 -272 14 -50 88 -129 147 -158 127 -61 307 -1 375 126 21 39 22 53 22 294 l0 254 -24 19 c-30 25 -62 24 -95 -2 l-26 -20 0 -227 c0 -160 -4 -234 -13 -252 -6 -14 -33 -36 -60 -49 -61 -30 -107 -23 -154 24 l-33 33 0 231 0 232 -25 24 c-13 14 -36 25 -50 25 -14 0 -37 -11 -50 -25z" />
      <path d="M2388 5106 c-25 -18 -37 -57 -27 -84 7 -18 115 -178 160 -238 6 -7 -30 -66 -91 -154 -112 -160 -122 -191 -74 -229 54 -43 77 -27 184 125 l82 116 85 -122 c47 -67 96 -126 109 -132 44 -20 104 24 104 76 0 7 -45 78 -100 156 -55 79 -100 148 -100 153 0 5 38 63 85 129 50 71 85 131 85 145 0 30 -42 73 -70 73 -37 0 -57 -18 -126 -115 -37 -52 -70 -95 -73 -95 -3 0 -33 39 -66 87 -33 48 -69 95 -79 105 -23 21 -64 23 -88 4z" />
      <path d="M4443 5110 c-45 -18 -55 -72 -22 -114 l20 -26 295 0 c281 0 295 1 314 20 30 30 27 83 -6 109 -26 20 -38 21 -303 20 -152 0 -286 -4 -298 -9z" />
      <path d="M4445 4819 c-49 -28 -58 -70 -24 -113 l20 -26 295 0 c281 0 295 1 314 20 25 25 26 71 1 101 l-19 24 -284 2 c-194 2 -289 -1 -303 -8z" />
      <path d="M4421 4504 c-12 -15 -21 -36 -21 -48 0 -31 34 -64 72 -71 18 -4 152 -5 296 -3 263 3 264 3 283 27 25 30 24 76 -1 101 -19 19 -33 20 -314 20 l-295 0 -20 -26z" />
      <path d="M70 4030 c-30 -30 -27 -83 6 -109 l27 -21 2457 0 2457 0 27 21 c34 27 36 79 4 109 l-22 20 -2468 0 c-2455 0 -2468 0 -2488 -20z" />
      <path d="M242 3555 c-86 -27 -151 -90 -180 -174 -8 -22 -12 -109 -12 -241 0 -227 1 -230 62 -245 22 -6 33 -1 58 23 l30 30 0 195 c0 182 1 196 21 221 45 58 25 56 681 56 580 0 604 -1 635 -20 66 -40 63 -7 63 -775 0 -624 -2 -701 -16 -731 -32 -66 -8 -64 -689 -64 -554 0 -618 2 -641 16 -48 32 -49 37 -54 432 l-5 374 -24 19 c-30 25 -76 24 -101 -1 -19 -19 -20 -33 -20 -402 0 -353 2 -386 19 -424 28 -61 85 -119 141 -143 49 -21 55 -21 703 -19 l654 3 49 30 c53 33 107 104 124 163 6 24 10 295 10 749 0 694 -1 714 -21 767 -26 71 -99 139 -172 161 -71 21 -1248 21 -1315 0z" />
      <path d="M2252 3551 c-76 -26 -118 -66 -157 -146 l-25 -50 1 -1570 c0 -1176 3 -1578 12 -1602 22 -60 72 -117 128 -148 l54 -30 1305 0 1305 0 54 30 c56 31 106 88 128 148 9 24 12 426 12 1602 l1 1570 -24 50 c-29 63 -77 112 -136 139 -45 21 -50 21 -1325 23 -1219 2 -1283 2 -1333 -16z m2598 -146 c15 -8 38 -27 49 -41 l21 -27 0 -1553 0 -1554 -34 -38 -34 -37 -1282 0 -1282 0 -34 37 -34 38 0 1553 c0 1719 -6 1576 64 1618 30 18 68 19 1284 19 1087 0 1257 -2 1282 -15z" />
      <path d="M3380 2999 c-261 -68 -466 -254 -551 -503 -23 -65 -27 -90 -20 -109 17 -44 77 -62 114 -34 9 6 28 43 42 80 41 113 76 168 160 252 129 129 263 185 445 185 182 0 316 -56 445 -185 135 -134 189 -266 188 -455 0 -86 -5 -120 -27 -187 -101 -311 -420 -495 -741 -428 -91 19 -218 83 -288 146 -72 64 -149 179 -177 266 -13 37 -29 75 -37 85 -22 25 -62 31 -90 13 -40 -26 -45 -56 -22 -127 115 -349 439 -569 805 -545 227 15 449 138 579 321 156 221 193 471 106 722 -41 119 -106 218 -207 313 -70 66 -105 91 -187 131 -56 26 -134 56 -174 64 -101 22 -268 20 -363 -5z" />
      <path d="M2824 1126 c-264 -83 -289 -453 -39 -576 l50 -25 722 -3 c712 -2 722 -2 778 19 156 58 235 242 174 404 -28 74 -100 146 -174 174 -55 21 -70 21 -763 20 -546 0 -716 -3 -748 -13z m1477 -159 c106 -71 106 -203 0 -274 l-34 -23 -697 0 -697 0 -34 23 c-52 35 -79 82 -79 137 0 55 27 102 79 137 l34 23 697 0 697 0 34 -23z" />
      <path d="M470 3093 c-32 -12 -50 -37 -50 -70 0 -51 29 -73 94 -73 47 0 57 4 75 26 34 43 26 86 -20 109 -28 15 -71 18 -99 8z" />
      <path d="M864 3089 c-22 -11 -44 -48 -44 -73 0 -7 9 -25 21 -40 l20 -26 238 0 c235 0 238 0 259 23 27 29 28 67 3 98 -19 24 -21 24 -248 26 -145 1 -236 -2 -249 -8z" />
      <path d="M448 2680 c-21 -16 -28 -30 -28 -56 0 -52 28 -74 94 -74 47 0 57 4 75 26 45 57 14 114 -65 121 -40 4 -54 1 -76 -17z" />
      <path d="M864 2689 c-22 -11 -44 -48 -44 -73 0 -7 9 -25 21 -40 l20 -26 238 0 c235 0 238 0 259 23 27 29 28 67 3 98 -19 24 -21 24 -248 26 -145 1 -236 -2 -249 -8z" />
      <path d="M448 2280 c-21 -16 -28 -30 -28 -56 0 -52 28 -74 94 -74 47 0 57 4 75 26 45 57 14 114 -65 121 -40 4 -54 1 -76 -17z" />
      <path d="M864 2289 c-22 -11 -44 -48 -44 -75 0 -8 11 -26 25 -39 l24 -25 234 0 c231 0 234 0 255 23 27 29 28 67 3 98 -19 24 -21 24 -248 26 -145 1 -236 -2 -249 -8z" />
      <path d="M234 1292 c-80 -28 -127 -72 -164 -154 -19 -41 -20 -67 -19 -483 0 -311 4 -449 12 -472 22 -60 72 -117 128 -148 l54 -30 634 -3 c570 -2 639 -1 684 14 59 19 129 81 160 139 21 39 22 51 25 479 3 510 4 505 -83 592 -81 81 -97 84 -480 84 -311 0 -323 -1 -349 -21 -33 -26 -36 -79 -6 -109 19 -19 33 -20 340 -20 356 0 374 -3 410 -62 19 -32 20 -49 20 -443 0 -389 -1 -412 -20 -442 -40 -66 -15 -64 -696 -61 l-616 3 -34 37 -34 38 0 424 c0 414 0 424 21 450 38 48 67 56 209 56 117 0 132 2 150 20 30 30 27 83 -6 109 -24 19 -40 21 -158 21 -98 -1 -144 -5 -182 -18z" />
      <path d="M515 705 c-14 -13 -25 -36 -25 -50 0 -14 11 -37 25 -50 l24 -25 358 0 c389 0 393 1 407 55 7 29 -15 79 -39 89 -9 3 -176 6 -371 6 l-355 0 -24 -25z" />
    </g>
  </svg>
);
export default SVGComponent;
