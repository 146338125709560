import React, { useState, useRef } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import formImg from "../images/form.jpg";
// import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactForm() {
    const form = useRef();
    // const recaptchaRef = useRef();
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        number: "",
        message: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            // if (form.checkValidity() === false || !recaptchaToken) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

    const sendingToastId = toast.info('Sending...', { autoClose: false });

        emailjs
            .send(
                "service_6qt8ur7",
                "template_s5v4n3a",
                formData,
                "NJPHlaeRF2s2k3rtO"
            )
            .then(
                (response) => {
                    console.log("Email sent successfully:", response);
                    // Reset form data
                    setFormData({
                        name: "",
                        email: "",
                        number: "",
                        message: "",
                    });
                    setValidated(false);
                    toast.update(sendingToastId, {
                        render: 'Submission successful',
                        type: 'success',
                        autoClose: 5000 // Adjust autoClose delay if needed
                    });
                },
                (error) => {
                    console.log("FAILED...", error.text);
                    toast.error("Something went wrong");
                }
            );
    };

    return (
        <Container className="contact-form-super-container">
            <ToastContainer position="top-right" />
            <Row xs={1} md={2} className="row-container">
                <Col>
                    <div className="contact-form-heading">
                        <div className="about-main-heading">GET IN TOUCH</div>
                        <div className="about-sub-heading">
                            Send Us a Message
                        </div>
                        <div className="about-para">
                            Have some suggestions or just want to say hi?
                            Contact us:
                        </div>
                        <Form
                            className="contact-form-container"
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                            ref={form}
                        >
                            <Form.Group
                                controlId="validationCustom01"
                                className="contact-form-group-container"
                            >
                                <InputGroup hasValidation>
                                    <Form.Control
                                        className="contact-form-input-field"
                                        required
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Your Name.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group
                                controlId="formBasicEmail"
                                className="contact-form-group-container"
                            >
                                <InputGroup hasValidation>
                                    <Form.Control
                                        className="contact-form-input-field"
                                        required
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please choose an Email.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group
                                controlId="formBasicMobileNumber"
                                className="contact-form-group-container"
                            >
                                <InputGroup hasValidation>
                                    <Form.Control
                                        className="contact-form-input-field"
                                        required
                                        type="tel"
                                        name="number"
                                        placeholder="Mobile Number"
                                        pattern="+[0-9]{15}"
                                        value={formData.number}
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter a Valid 10-Digit Contact
                                        Number.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group
                                controlId="exampleForm.ControlTextarea1"
                                className="contact-form-group-container"
                            >
                                <InputGroup hasValidation>
                                    <Form.Control
                                        className="contact-form-textarea-input-field"
                                        required
                                        as="textarea"
                                        rows={3}
                                        name="message"
                                        placeholder="Message..."
                                        value={formData.message}
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Write Your Message here...
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            {/* <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LeDwbkpAAAAAEE_c12L-pohCTZO87kEVt0_niyi"
                                // onChange={handleRecaptchaChange}
                            /> */}
                            <div className="btn-container" type="submit">
                                <Button
                                    type="submit"
                                    className="lightbox-image theme-btn btn-style-two"
                                >
                                    <span className="txt">Send Message</span>
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Col>
                <Col className="contact-form-img-col">
                    <img id="contact-form-images" src={formImg} alt="about" />
                </Col>
            </Row>
        </Container>
    );
}

export default ContactForm;
