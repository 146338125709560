import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
// import Navigationbar from '../components/navbar';
import ServiceDetails from '../components/services-top-details';
import Banner from '../components/page-banner';
import Footer from '../components/footer';
import { Data } from '../components/Data';
import Experts from '../components/experts';
import ContactCard from '../components/contact-card';
import Serwhychoose from '../components/ser-WhyChoose';
import Faqs from '../components/faqs';
import ReactBanner from '../images/react-banner2.png';
import { NavbarWithMegaMenu } from "../NavbarWithMegaMenu";
import { Helmet } from 'react-helmet-async';

export default function Android() {
  return (
    <div className="page-super-container services-page">
      <Helmet>
            <title>Android App Development Services | Enhance Your Business Value - Montech Design</title>
            <meta
                name="description"
                content="Invest in professional Android app development services to expand your business reach globally and streamline internal processes. Our custom Android apps empower businesses with operational efficiency and new revenue opportunities."
            />
            <meta
                name="keywords"
                content="Android app development services, Custom Android applications, Global reach with Android apps, Android developers for business, Enhance business with Android apps, Streamline operations with Android apps, IT services Android development, Android app for productivity, Android app solutions, Business growth with Android apps"
            />
        </Helmet>

      {/* <Navigationbar /> */}
      <NavbarWithMegaMenu />
      <Banner page="Android" bannerimg={ReactBanner} />

      <div>
        {
          Data.androidpage.map(
            (value) => {
              return <ServiceDetails
                Heading={value.Heading}
                para={value.para}
                images={value.images}
                data={Data.serAndroidCard}
              />
            }
          )
        }
      </div>

      {/* Card */}
      <div className="contact-super-container">
        <Container className="contact-main-container">
          <Row>
            <Col>
              <div className="about-main-heading">REALIZE YOUR VISION THROUGH AN ANDROID APPLICATION</div>
              <div className="about-sub-heading">Enhance your business with resilient Android app development services that bring substantial value.</div>
            </Col>
          </Row>
          <Row xs={1} sm={2} md={2} lg={3} className="contact-card-container">
            {
              Data.serAndroidPaheCard.map(
                (value) => {
                  return <>
                    <ContactCard contactIcon={value.aboutcardtIcon} contactHeading={value.aboutcardheading} contactPara={value.aboutcardpara} />
                  </>
                }
              )
            }
          </Row>
        </Container>
      </div>

      <Experts
        serHeading="ANDROID DEVELOPMENT SERVICES"
        sersubHeading="Invest In Android App Development Services To Augment The Value Proposition Of Your Business"
        data={Data.androidexpert}
      />
      
      <div>
        {
          Data.serWhyChooseAndroid.map(
            (value) => {
              return <Serwhychoose
                Heading={value.Heading}
                subHeading={value.subHeading}
                para1={value.para1}
                para2={value.para2}
                para3={value.para3}
                images={value.images}
              />
            }
          )
        }
      </div>

      <Faqs
        faqHeading="FREQUENTLY ASKED QUESTIONS FOR ANDROID DEVELOPMENT SERVICES"
        faqsubHeading="Discover Answers to Common Queries Regarding Android Development Services"
        data={Data.androidFaqs}
      />

      <Footer />

    </div>
  )
}
