import { Container, Row, Col, Card, TabContainer } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
// import { Data } from './Data';

function Experts({ serHeading, sersubHeading, data }) {
    // const [activeTab, setActiveTab] = useState('first');
    const [activeTab, setActiveTab] = useState('0');

    return (
        <Container className="exp-main-container">
            <Row>
                <Col>
                    <div className="about-main-heading">{serHeading}</div>
                    <div className="about-sub-heading">{sersubHeading}</div>
                </Col>
                {/* <Col className="exp-para-col">
                    <div className="exp-para">
                        Integrio deep industry expertise enables global brands to hit the ground running.
                    </div>
                </Col> */}
            </Row>
            <TabContainer activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
                <Row xs={2} sm={2} md={3} lg={3} xl={6} className="card-container">
                    {data.map((value, index) => (
                        <Col className="container-fluid mt-4" key={index}>
                            {/* <Nav className="exp-nav">
                                <Nav.Item> */}
                                    <Nav.Link className="contant-card-link" eventKey={index.toString()} active={activeTab === index.toString()}>
                                        <Card className={`expert-card ${activeTab === index.toString() ? 'active' : ''}`}>
                                            {/* <div
                                                className="expert-img"
                                                dangerouslySetInnerHTML={{ __html: value.expIcon }} /> */}
                                            <div className="hash-code">{value.exphashnum}</div>
                                            <Card.Body className="expert-main-content">
                                                <Card.Title className="expert-heading">{value.expHeading}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Nav.Link>
                                {/* </Nav.Item>
                            </Nav> */}
                        </Col>
                    ))}

                    {/* {
                        Data.expert.map(
                            (value) => {
                                return <><Col className="container-fluid mt-4" key={value.expKey}>
                                    <Nav className="exp-nav">
                                        <Nav.Item>
                                            <Nav.Link eventKey={value.expKey} active={activeTab === value.expKey}>
                                                <Card className={`expert-card ${activeTab === value.expKey ? 'active' : ''}`}>
                                                    <div
                                                        className="expert-img"
                                                        dangerouslySetInnerHTML={{ __html: value.expIcon }} />
                                                    <Card.Body className="expert-main-content">
                                                        <Card.Title className="expert-heading">{value.expHeading}</Card.Title>
                                                    </Card.Body>
                                                </Card>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col></>
                            }
                        )
                    } */}
                </Row>
                <Container className="exp-tab-container">
                    <Tab.Content>
                        {data.map((value, index) => (
                            <Tab.Pane key={index} eventKey={index.toString()}>
                                <Row xs={1} md={2} className="row-container">
                                    <Col className="exp-tab-col">
                                        <img className="exp-tab-img" src={value.exptabImg} alt="about" />
                                    </Col>
                                    <Col className="exp-tab-col-contant">
                                        <div className="exp-content-heading">
                                            <div className="exp-tab-heading">{value.exptabHeading}</div>
                                            <div className="exp-tab-para">{value.exptabPara1}</div>
                                            <div className="exp-tab-para">{value.exptabPara2}</div>
                                        </div>
                                    </Col>

                                </Row>
                            </Tab.Pane>
                        ))}



                        {/* {
                            Data.expert.map(
                                (value) => {
                                    return <>
                                        <Tab.Pane eventKey={value.expKey} key={value.expKey}>
                                            <Row xs={1} md={2} className="row-container">
                                                <Col className="exp-tab-col">
                                                    <img className="exp-tab-img" src={value.exptabImg} alt="about" />
                                                </Col>
                                                <Col className="exp-tab-col-contant">
                                                    <div className="exp-content-heading">
                                                        <div className="exp-tab-heading">{value.exptabHeading}</div>
                                                        <div className="exp-tab-para">{value.exptabPara1}</div>
                                                        <div className="exp-tab-para">{value.exptabPara2}</div>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Tab.Pane>
                                    </>
                                }
                            )
                        } */}
                    </Tab.Content>
                </Container>
            </TabContainer >
        </Container>
    );
}

export default Experts;
