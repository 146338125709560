import React, { Component } from "react";
import Slider from "react-slick";

import Title from "./title";

import { Data } from "./Data";

export default class Productslider extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        const settings = {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 3,
            speed: 1000,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            pauseOnHover: false,
            responsive: [
                {
                    breakpoint: 2560,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true
                    }
                },
                {
                    breakpoint: 2100,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true
                    }
                },
                // {
                //     breakpoint: 1440,
                //     settings: {
                //         slidesToShow: 2,
                //         slidesToScroll: 2,
                //         infinite: true
                //     }
                // },
                // {
                //     breakpoint: 1024,
                //     settings: {
                //         slidesToShow: 2,
                //         slidesToScroll: 2,
                //         infinite: true,
                //     }
                // },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true
                        // centerPadding: "60px"
                    }
                },
                // {
                //     breakpoint: 768,
                //     settings: {
                //         slidesToShow: 1,
                //         slidesToScroll: 1,
                //         infinite: true,
                //     }
                // },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        infinite: true,
                        centerPadding: "30px",
                    }
                },
                // {
                //     breakpoint: 480,
                //     settings: {
                //         slidesToShow: 1,
                //         slidesToScroll: 1,
                //         initialSlide: 1,
                //         infinite: true
                //     }
                // },
                // {
                //     breakpoint: 376,
                //     settings: {
                //         slidesToShow: 1,
                //         slidesToScroll: 1,
                //         initialSlide: 1,
                //         infinite: true
                //     }
                // }
            ]
        };
        return (
            <div className="main-product-container">
                <Title titleName={'RECENT WORKS'} subTitle={'Our Latest Projects'} />
                <Slider className="slider-container" ref={c => (this.slider = c)} {...settings}>
                    {/* {
                        Data.productimg.map(
                            (value) => {
                                return <div key={value.prodkey} className="product-slider">
                                    <img src={value.prod1} alt="product" />
                                </div>
                            }
                        )
                    } */}
                    {
                        Data.PortfolioData.map(
                            (value) => {
                                return <div key={value.id} className="product-slider">
                                    <img src={value.image} alt="product" />
                                </div>
                            }
                        )
                    }
                </Slider>
                <div className='main-nex-prv'>
                    <div className='next-prv-btn'>
                        <div className='prv-btn' onClick={this.previous}>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="25px" height="25px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#232323" stroke="none">
                                    <path d="M2050 4249 c-35 -15 -220 -195 -813 -787 -515 -514 -774 -780 -787 -808 -24 -49 -26 -130 -5 -182 22 -51 1533 -1564 1591 -1592 57 -28 138 -27 195 3 64 34 102 93 107 166 8 112 33 83 -601 718 l-581 583 1700 2 1701 3 39 27 c21 15 50 44 64 65 21 31 25 48 25 113 0 65 -4 82 -25 113 -14 21 -43 50 -64 65 l-39 27 -1701 3 -1701 2 577 578 c608 608 617 619 618 707 0 44 -36 131 -66 157 -33 29 -107 58 -149 58 -22 0 -60 -10 -85 -21z" />
                                </g>
                            </svg>
                        </div>
                        <div className='bet-line'></div>
                        <div className='next-btn' onClick={this.next}>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="25px" height="25px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#232323" stroke="none">
                                    <path d="M3115 4244 c-107 -55 -153 -178 -106 -279 13 -27 205 -225 590 -610 314 -313 571 -573 571 -577 0 -5 -853 -8 -1895 -8 -1688 0 -1900 -2 -1933 -15 -169 -71 -169 -319 0 -390 33 -13 245 -15 1933 -15 1042 0 1895 -3 1895 -7 0 -4 -263 -271 -584 -593 l-584 -585 -11 -55 c-33 -160 109 -292 267 -250 44 12 98 64 825 789 477 475 787 792 799 816 28 54 28 136 0 190 -31 59 -1546 1570 -1594 1590 -48 19 -135 19 -173 -1z" />
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}